import { type ComponentProps } from "react";

import { cn } from "~/styles";

export function Input({ className, ...props }: ComponentProps<"input">) {
  return (
    <input
      className={cn(
        "bg-transparent px-4 py-2 border-border border rounded-md",
        "transition-colors",
        "focus:placeholder:text-primary-300 focus:outline outline-offset-0 outline-primary/30 outline-4 focus:border-primary focus:bg-primary/5",
        className
      )}
      {...props}
    />
  );
}
